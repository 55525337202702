/**
 * External Dependencies
 */
import React from "react";

/**
 * Internal Dependencies
 */
//  import { RegisterContextProvider } from "./context/register-context";
import AsyncComponent from "../../../components/async-component";
import PageWrap from "../../../components/page-wrap";
import PageContent from "../../../components/page-content";
import { updateBreadcrumb } from "../../../utils/breadcrumbs";
import { DocumentRepositoryProvider } from "./context/document-repository-context";

/**
 * Component
 */
class CDRDocumentRepository extends React.Component {
    componentDidMount = updateBreadcrumb;

    render() {
        return (
            <PageWrap>
                <PageContent className="cdr-page">
                    <AsyncComponent component={() => import("./content")} />
                </PageContent>
            </PageWrap>
        );
    }
}

export default CDRDocumentRepository;
