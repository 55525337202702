import React from "react";

import { Modal, ModalBody } from "reactstrap";

import { GT, Icon } from "components/index";

interface IModalPlayer {
    file: any;
    modalOpen: boolean;
    closeModal: () => void;
}

export const ModalPlayer = (props: IModalPlayer) => {
    const { modalOpen, file, closeModal } = props;

    return (
        <Modal isOpen={modalOpen} fade toggle={closeModal} className="player-modal">
            <video controls width={"100%"} src={file.file}></video>
        </Modal>
    );
};
