import React, { useEffect, useRef, useState } from "react";

import { KekuleInput } from "components/kekule-input/kekule-input";
import { memoFilter } from "./memoFilter";

import * as FI from "../interfaces/filter-interfaces";
import { useTranslation } from "react-i18next";
import { Input } from "reactstrap";

interface IKekuleFilterProps {
    inputData: FI.IPrimeFilterData;
}

export const MemoKekuleFilter = memoFilter((props: IKekuleFilterProps) => {
    return <KekuleFilter {...props} />;
});

const KekuleFilter = ({ inputData }) => {
    const { column, onFilterChange, tableRef, filter, tableName, filterFocusedFieldName, setFilterFocusedFieldName } = inputData;

    const [input, setInput] = useState<any>();
    const [isEditor, setIsEditor] = useState<boolean>(false);

    const { t } = useTranslation();

    const ref = useRef<any>(null);

    const handleInit = (chemViewer) => {
        ref.current = chemViewer;
        chemViewer.openEditor();
    };

    useEffect(() => {
        onFilterChange({ value: input?.smiles, name: "smiles_search" });
    }, [input]);

    const handleClearEditor = (e) => {
        e.stopPropagation();
        setInput(undefined);
        setIsEditor(false);
    };

    return (
        <div
            role="button"
            key={`prime-filter-input-${column.field}-${tableName}-container`}
            className="custom-search-table-input kekule-filter"
            onClick={() => (!isEditor ? setIsEditor(true) : ref.current.openEditor())}
        >
            {/* <span className="placeholder">{t("Filter...") || input?.smiles}</span> */}
            <Input
                key={`prime-filter-input-${column.field}-${tableName}`}
                name={column.field}
                className="prime-table-filter-input-font"
                placeholder={`${t("Filter")} ${t(column.header)}`}
                value={input?.smiles || ""}
                readOnly
            />
            {isEditor && <KekuleInput value={input} onChange={setInput} noLabel onInit={handleInit} />}
            <span onClick={handleClearEditor} className="pi pi-times state-input-clear-button" />
        </div>
    );
};
