import { MenuItem } from "primereact/menuitem";
import { ReactElement, ReactNode } from "react";

import { OnContextColumnFieldChangeType, IAuditData } from "./context-interfaces";
import { FilterBodyType, IPrimeTableFilters } from "./filter-interfaces";
import { BodyType } from "./placeholders-interfaces";
import { EditBodyType } from "./edit-interfaces";
import { IData } from "hooks/useGetData";

export type IBaseRecord = {
    id: number;
};

//# ///////////// TABLE PROPS /////////////
export type IPrimeDataTableBase<T extends IBaseRecord = IBaseRecord> = {
    dataSet: T[] | undefined;
    columns: IPrimeTableColumn[];
    setData?: TableSetData<T>;
    customHeader?: (column: IGeneratedPrimeTableColumn) => ReactNode;
    tableName: string; // props tableName is a string, this props HAS to be unique in entire project, this prop is just a table name, which is needed when saving column(size, order, hidden/visible) configuration in database
    dynamicColumns?: boolean; // defines if column config should be saved and fetched

    // START pagination section
    rowsPerPage?: number[]; // via this property you can set options for how many rows there are per page
    // END pagination section

    // START visual settings section
    className?: string; // custom dataTable container className
    children?: ReactElement | ReactElement[]; // element send via this prop will be displayed above data table, this prop was mainly created with buttons in mind
    btnContainerClassName?: string; // default value which is needed for button container to preserve it height when no button is rendered/ if you want to disable this additional padding because you have no buttons then just send empty string
    showGridlines?: boolean; // if set to false then there are no lines between columns
    reorderColumnsEnabled?: boolean; // if this props is set to false then you can't reorder columns
    resizableColumnsEnabled?: boolean; // if this props is set to false then you can't resize columns
    minimumColWidth?: number; // column minimum width
    defaultTableHeight?: number; // table minimum height
    disableHeadersTranslation?: boolean; // if set to true column headers are not translated
    isLoading?: boolean; // set loading state
    isPending?: boolean; // set pending state
    adjustTableHeight?: number; // number of pixels should be added to calculated height
    // END visual settings section

    // START select record section
    selectedRecords?: T[]; // variable that holds selectedRecords, if not passed then row selection is disabled, selectedRecords holds entire row data, not only row id (obligatory for row selection)
    onSelect?: OnSelectType<T>; // function that sets new selected records to your state (obligatory for row selection) (e.g onSelect={(selected) => setSelectedTrials(selected)})
    notSelectFirst?: boolean; // if set to true, first row will not be selected when table loads or when new data will be fetched (optional for row selection))
    selectionMode?: SelectionModeType; // sets if user can select only one record or many
    idSelector?: string; //this prop decides which property in record object is a record id, by default its set to "id", you have to use dataSet={data?.data as any}, and selectedRecords={selectedRecord as any} with this prop
    // END select record section

    // START sorting, grouping and filtering section
    sortable?: boolean; // if set to false, sort will be disabled (it is needed to pass sortable prop as true to both, table and column component)
    // END sorting, grouping and filtering section

    // START context menu section, if any prop below is set to true or has correct data, then context menu will be enabled and context menu item will show when right clicked on table
    auditData?: IAuditData; // prop that enables context menu ITEM with which user can open audit modal, (e.g auditData={{ appLabel: "trialmanagement", model: "TrialManagement" }})
    exportCSVEnabled?: boolean; // prop that is an array of objects, context menu will generate new context menu ITEMS from this array (e.g contextMenuItems = {[{name:string, function:function()}]})
    hidingColumnEnabled?: boolean; // if set to false, user can't hide columns, because "hide column" context menu item is hidden
    filterEnabled?: boolean; // if set to false, user can't show/hide header filters
    frozenColumnEnabled?: boolean; // if set to false, user can't froze columns, because "froze column" context menu item is hidden
    translateDisabled?: boolean; // prop that disables data translation
    contextMenuItems?: MenuItem[]; // prop that enables context menu ITEM with which user can open communication log modal,
    onContextColumnFieldChange?: OnContextColumnFieldChangeType;

    // END context menu section

    handleDoubleClick?: Function; // prop in which you can pass function thats fires when user double click on row

    // START pagination section
    meta: IMeta | undefined | null; // if not passed pagination is disabled (obligatory for pagination)
    onReload?: OnReloadType; // function that gets new data, it returns params that you have to pass to your url (obligatory for pagination)
    // END pagination section

    // START sorting and grouping section
    // A) If you want to reset filtering and sorting while your parent table change selected record, use higherDependencyId prop.
    // B) If you want to save All filtering, and sorting and use it when your parent table changes selected record, use handleParams prop. eq handleParams={params => handleParams(Params)} then you have to use generateUrlParams function to create correct url, you can find example in file: pages > result > content,
    // C) if you want to save only grouping, and reset everything else when your parent table changes selected record, you have to USE BOTH higherDependencyId and handleParams, in the same way as described above, primaDataTable will know to reset all params beside grouping param in table state, but you will have to still clear param state in your app (check example in file: pages > result > content)
    onParamsChange?: OnParamsChangeType; // callback which will return current params, it fires when filters or sort property changes (you can use either handleParams or higherDependencyId to manage params state which holds information about filtering and sorting (sorting includes grouping))
    higherDependencyId?: any[]; // if dataSet in child table (eq.results) changes when you change selected record in parent table (eq.samples) you have to pass selectedRecords in two dimensional array (eq. higherDependencyId={[selectedRecords]}) when selectedRecords=[], if your child table has more then one parent table pas both selectedRecords arrays, it is needed for clearing filters and sorted columns

    selectChoices?: ISelectChoicesObj | null | undefined; // object that holds one or many arrays, each array is meant to one column, object property has to be named after column field, array is passed to filter input as options in header or in cell edit, (obligatory if you are using select input in header filter or edit cell, select can have custom options different for each row, but you have to pass them directly to the component, options can be hardcoded or returned from backend (like in result application))

    groupRowsEnabled?: boolean; // if set to false, then row groups are disabled, and you won't see context item that you can use to open grouping modal
    customRowGroupHeader?: any; // if grouping is enabled then in this prop you can send function that returns custom row group header
    customRowGroupFooter?: any; // if grouping is enabled then in this prop you can send function that returns custom row group footer
    useFetchTableData?: boolean; // set this to true if you use new hook, useGetData needs to reload additional time to fetch the data when grouping is set to true, new hook skips it that why you need this flag
    // END sorting and grouping section

    // START editing section
    cellEditEnabled?: boolean; // if set to true, cell editing will be enabled, it is mostly here for perm code use
    onEdit?: OnEditType<T>; // edit event callback
    handleCustomResponse?: HandleCustomResponse | undefined; // used to handle response when editing not the actual record that is in the table but something else
    extraEditParams?: any; // prop in which you can pass additional params which will be send in edit request (not in modal but when you use edit component in table), if data you want to send is available in the row you are editing, use extraEditParams prop in edit component with which you are editing data, not in table
    customRefresh?: Function; //  function that you will pass thru customRefresh prop will fire, if Edit component that you are using has its shouldDataSetRefresh props set to true, or of you are using customMoreModal prop that is available on EditSelectInput and EditTextareaInput, in modal that you will pass thru this prop you are able to use handleSubmit function, it takes two arguments, value (edit value) and shouldDataSetRefresh, if the second is true, customRefresh will fire
    customHandleEditSubmit?: Function;
    hideSuccessToast?: boolean;

    // END editing section

    // START advanced filter section
    advancedFiltersConfig?: IAdvancedFilterConfig[];
    aiFilteringEnabled?: boolean;
    // END advanced filter section

    // START other section
    refreshCallBackFunctions?: any[]; // array of variables that will refresh useCallback functions in prime data table
    disableAdditionalColumns?: boolean; // disable additional columns, used for dual table
    // END other section
};

type TableEditObligatoryProps = {
    permCode: string;
    firstPartEditUrl: string;
};

type TableEditNonObligatoryProps = {
    permCode?: undefined;
    firstPartEditUrl?: undefined;
};

export type IPrimeDataTable<T extends IBaseRecord = IBaseRecord> = IPrimeDataTableBase<T> &
    (TableEditNonObligatoryProps | TableEditObligatoryProps);

//$ ///////// TABLE CALLBACK PROPS TYPES /////////
export type OnParamsChangeType = (params: IPrimeParams) => void;
export type OnReloadType = (params?: string | undefined, refresh?: boolean | undefined) => any;
export type OnSelectType<T> = (arg: T[]) => void;
export type OnEditType<T> = (
    arg2: { target: { name: string; value: any }; extraParametersToSet?: { [key: string]: any } },
    arg3: T
) => void;

//$ ///////////// TABLE PROPS TYPES /////////////
export type SelectionModeType = "single" | "multiple";

export interface IAdvancedFilterConfig {
    columns: IPrimeTableColumn[];
    prefix?: {
        filterLabel: string;
        filterField: string;
    };
}

export interface ISelectChoicesObj {
    [key: string]: ISelectChoices[] | any;
}

export type ISelectChoices = ILabelValue<string | number>;

//# //////////// TABLE META INTERFACES ////////////
export interface IMeta {
    pageSize: number;
    lastPage: number;
    page: number;
    count: number;
    ModelInfo?: IModelInfo;
    ExtraFields: ExtraFields;
}

export interface IModelInfo {
    appModelLabel: string;
    app: string;
    model: string;
    isTranslatable?: boolean;
}

export interface IPrimeMeta {
    first?: number;
    rows?: number;
    page: number;
    totalRecords?: number;
}

//# ///////// TABLE COLUMNS INTERFACES //////////
export interface ILitePrimeTableColumn {
    header: string;
    field: string;
}

export interface IBasePrimeTableColumn {
    header: string; // Column header name
    field: string; // string that is object property which is in dataSet array, after which table looks for data in

    sortFieldName?: string; // custom fieldName that substitutes field prop when sorting (it is needed when you need to get different fieldName then default in url request)
    filterFieldName?: string; // custom fieldName that substitutes field prop when filtering (it is needed when you need to get different fieldName then default in url request)

    headerClassName?: string; // header className
    className?: string; // column className

    body?: BodyType; // you have to pass function that returns component, it is then passed to each cell in that column, it can be prettyCell something that displays data

    //! TODO zmienić editBody?: Function => editBody?: EditBodyType
    editBody?: EditBodyType; // you have to pass function that returns component, it is then passed to each cell in that column, it has to be pre prepared edit component that can edit data

    frozen?: boolean; // if set to true then column position is frozen on screen
    grouped?: boolean; // table will be grouped on init on this column if groupedBy is set to true

    sortable?: boolean; // if set to true sort is enabled for this column, note that you also have to set prop sortable to true in PrimeDataTable component
    filterBody?: FilterBodyType; // in this props you pass function that returns component that will be used as filter input in header
    clientSideFiltration?: boolean; // if set to true then filtration is set to be on client side (works only in clientSideTable)

    additionalType?: ExtraFieldTypes;
    noHeaderTranslation?: boolean;
}

export interface IPrimeTableColumn extends IBasePrimeTableColumn {
    width?: number;
    filterOptionConfig?: {
        app: string;
        model: string;
        field?: string;
    };
    lookupChoices?: ILabelValue<string>[];
    translateOptions?: boolean;
}

export interface IGeneratedPrimeTableColumn extends IBasePrimeTableColumn {
    width: string;
    additionalType?: ExtraFieldTypes;
    lookupChoices?: ILabelValue<string>[];
    translateOptions?: boolean;
}

export interface IGeneratedPrimeColumns {
    visible: IGeneratedPrimeTableColumn[];
    default: IGeneratedPrimeTableColumn[];
    columnGenerateStatus: "NOT_GENERATED_COLUMNS" | "GENERATED_COLUMNS" | "PLACEHOLDER_COLUMNS";
}

//# //////// TABLE INTERNAL INTERFACES ////////
export interface IPrimeParams {
    multiSortMeta?: DataTableMultiSortMetaType;
    filters?: IPrimeTableFilters;
}

export type DataTableMultiSortMetaType = DataTableSortMeta[] | undefined;

export interface DataTableSortMeta {
    field: string;
    order: DataTableSortOrderType;
    header?: string;
    groupByThisField?: boolean;
}

type DataTableSortOrderType = 1 | 0 | -1 | undefined | null;

export interface IUserTableConfig {
    columns: IGeneratedPrimeTableColumn[];
    filtersEnabled: boolean;
}

export interface IScrollConfig {
    vertical: boolean;
    horizontal: boolean;
}

export interface ITableEnabledFunctionality {
    pagination: boolean;
    groupingEnabled: boolean;
    groupingSelected: boolean;
    sortable: boolean;
}

export type HandleScrollConfigType = "vertical" | "horizontal" | "default";

export type ListActionType =
    | { type: "columns"; payload: { columns: IGeneratedPrimeTableColumn[] } }
    | { type: "filterEnabled"; payload: { filterEnabled: boolean } };

export interface ITableConfig {
    contextItemFilterEnable: boolean;
    filtersEnabled: boolean;
    frozenColumn: FrozenColumnType;
    tableHeight: number;
}

export type FrozenColumnType = IFrozenColumn | undefined;
interface IFrozenColumn {
    field: string;
    width: string;
    originalPosition: number;
}

//$ /////////// ADDITIONAL COLUMNS ///////////
export type ExtraFieldsKeys =
    | "extraField1"
    | "extraField2"
    | "extraField3"
    | "extraField4"
    | "extraField5"
    | "extraField6"
    | "extraField7"
    | "extraField8"
    | "extraField9"
    | "extraField10";

export type ExtraFieldTypes = "str" | "date" | "int" | "float" | "list";

export interface ExtraField {
    name: string;
    type: ExtraFieldTypes;
    lookup?: number;
}

export type ExtraFields = {
    [key in ExtraFieldsKeys]?: ExtraField;
};

export enum ADDITIONAL_COLUMNS_EVENT_ACTION {
    ADD = "ADD",
    EDIT = "EDIT",
    DELETE = "DELETE",
}

export type HandleAdditionalColumnsEvent = AdditionalColumnEventAdd | AdditionalColumnEventEdit | AdditionalColumnEventDelete;

export type HandleAdditionalColumns = (arg: HandleAdditionalColumnsEvent) => void;

type AdditionalColumnEventAdd = {
    payload: { column: IPrimeTableColumn; selectedContextColumnField?: string };
    action: ADDITIONAL_COLUMNS_EVENT_ACTION.ADD;
};
type AdditionalColumnEventEdit = { payload: { column: IPrimeTableColumn }; action: ADDITIONAL_COLUMNS_EVENT_ACTION.EDIT };
type AdditionalColumnEventDelete = { payload: { extraField: ExtraFieldsKeys }; action: ADDITIONAL_COLUMNS_EVENT_ACTION.DELETE };

export type TableSetData<T> = React.Dispatch<React.SetStateAction<IData<T>>>;

export type HandleCustomResponse = (editData: any, r: any) => { [key: string]: any };
