import { convertDotsToUnderscores } from "./primeHelpers";
import { IPrimeParams } from "../interfaces";

export interface IGenerateUrlParamsArguments extends IPrimeParams {
    page?: number;
    rows?: number;
}

export const generateUrlParams = ({
    page = 1,
    rows = 10,
    multiSortMeta = undefined,
    filters = undefined,
}: IGenerateUrlParamsArguments): string => {
    let url = `?pageSize=${rows}&page=${page}&`;

    if (multiSortMeta) {
        if (multiSortMeta[0].groupByThisField) {
            const grouping = multiSortMeta[0];
            url = url.concat(`grouping=${grouping.order && grouping.order < 0 ? "-" : ""}${convertDotsToUnderscores(grouping.field)}&`);
        }

        multiSortMeta.forEach((sort, index) => {
            url = url.concat(
                `${index > 0 ? "," : "ordering="}${sort.order && sort.order < 0 ? "-" : ""}${convertDotsToUnderscores(sort.field)}`
            );
        });

        url = url.concat("&");
    }

    if (filters) {
        Object.keys(filters).forEach((fieldName) => {
            filters[fieldName].forEach((fieldArray) => {
                const { value, filteringType } = fieldArray;

                // kekule molecule filter
                if (fieldName === "smiles_search") return (url = url.concat(`${fieldName}=${value}&`));

                if (typeof value != "string" && typeof value != "number") {
                    if (value.endDt != null)
                        url = url.concat(
                            `search_field=${fieldName}__gte&search=${value?.startDt}&search_field=${fieldName}__lte&search=${value.endDt}&`
                        );
                    else url = url.concat(`search_field=${fieldName}__icontains&search=${value.startDt}&`);
                } else url = url.concat(`search_field=${fieldName}${filteringType ?? ""}&search=${value}&`);
            });
        });
    }

    url[url.length - 1] == "&" && (url = url.substring(0, url.length - 1));

    return url;
};
