import React from "react";

import Axios from "axios";

import { sweetConfirm } from "components/sweet-alert/sweetConfirm";
import { handleRequest } from "utils/handleRequest";
import addToast from "utils/addToast";
import { Button } from "..";
import { mapToIds } from "components/prime-data-table/helpers";

interface IDeleteButtonProps {
    url: string;
    instanceName: string;
    disabled: boolean;
    instances: any[];
    permCode: string;
    pluralForm?: string;
    refreshData: () => void;
    onDelete?: (response) => void;
    onBeforeDelete?: () => Object;
}

export const DeleteButton = (props: IDeleteButtonProps) => {
    const { url, instanceName, disabled, instances, pluralForm, permCode, refreshData, onDelete, onBeforeDelete } = props;

    const ids = mapToIds(instances);

    const handleDelete = async (e, reqParams) => {
        const instancesLength = ids.length;

        if (
            !(await sweetConfirm("Delete", {
                text: `Do you want to delete ${pluralForm ? pluralForm : `${instanceName.toLowerCase()}(s)`}?`,
                count: instancesLength,
            }))
        )
            return;

        const requestData: Object = (onBeforeDelete && (await onBeforeDelete())) || {};

        await handleRequest({
            request: () => Axios.delete(url, { data: { ids, reqParams, ...requestData } }),
            onSuccess: async (response) => {
                await refreshData();

                onDelete && onDelete(response);

                const deletedItemForm = pluralForm || `${instanceName}${instancesLength > 1 ? "s" : ""}`;
                addToast({ title: `${deletedItemForm} has been deleted.`.firstLetterToUpperCase() });
            },
        });
    };

    return <Button permCode={permCode} text="Delete" color="danger" onClick={handleDelete} disabled={disabled} icon="trash" reqModal />;
};
