import React, { forwardRef, RefObject, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState } from "react";

import classnames from "classnames/dedupe";
import { useTranslation } from "react-i18next";
import { Kekule } from "kekule";

import "./kekule-input.styles.scss";

interface IKekuleInput {
    readonly?: boolean;
    value?: any;
    name?: string;
    className?: string;
    noLabel?: boolean;
    onChange?: ({ molfile, smiles }, { name }) => void;
    onInit?: (ref: RefObject<HTMLDivElement>) => void;
}

export const KekuleInput = (props: IKekuleInput) => {
    const { value, name = "molfile", readonly, className, noLabel, onChange, onInit } = props;

    const kekuleRef = useRef<any>(null);
    const kekuleContainerRef = useRef<HTMLDivElement>(null);

    const { t } = useTranslation();

    // layout effect enables reaching the reference in useImperativeHandle, order of hooks useLayoutEffect => useImperativeHandle => useEffect!
    useEffect(() => {
        const initKekule = () => {
            // get element
            const element = kekuleContainerRef.current;
            const chemViewer = new Kekule.ChemWidget.Viewer(element);

            // kekule setup vars
            chemViewer.shareEditorInstance = true;
            chemViewer.restrainEditorWithCurrObj = true;
            chemViewer.enableEditFromVoid = true;
            chemViewer.showCaption = true;

            // kekule pretty colors
            const renderConfigs = new Kekule.Render.Render2DConfigs();
            renderConfigs.getColorConfigs().setUseAtomSpecifiedColor(true);

            if (value) {
                // get mol & formula
                const molecule = Kekule.IO.loadFormatData(value, "mol");
                // init mol
                chemViewer.setChemObj(molecule);
            }

            // toolbar - some kinda hover stuff
            if (!readonly) {
                chemViewer
                    // .setEnableToolbar(true)
                    .setEnableDirectInteraction(true)
                    .setEnableEdit(true)
                    // .setToolbarPos(Kekule.Widget.Position.TOP_RIGHT)
                    .setToolButtons(["openEditor"])
                    .setAutofit(true);
            }

            chemViewer.setEditorProperties({ renderConfigs: renderConfigs });

            kekuleRef.current = chemViewer;
            onInit && onInit(chemViewer);

            chemViewer.on("load", () => {
                updateMoleculeData();
            });
        };

        initKekule();
    }, []);

    const updateMoleculeData = () => {
        // enable pretty colors
        kekuleRef.current.getChemObj()?.setRenderOption("useAtomSpecifiedColor", true);

        // get object in viewer
        const molecule = kekuleRef.current.getChemObj();

        // export molecule to MDL/smiles
        // const cml = Kekule.IO.saveFormatData(molecule, 'cml')
        const molfile = Kekule.IO.saveFormatData(molecule, "mol");
        const smiles = Kekule.IO.saveFormatData(molecule, "smi");

        onChange && onChange({ molfile, smiles }, { name });

        // 'load' event can trigger multiple times, return if it wasn't caused by structure change
        //if (kekule.parentState.section.value == molData) return;

        // get & set formula
        if (molecule.calcFormula) {
            const formula = molecule.calcFormula();
            kekuleRef.current.caption = formula.getText();
        } else {
            kekuleRef.current.caption = null;
        }
    };

    const openEditor = () => {
        kekuleRef.current.openEditor();
    };

    return (
        <div className={className}>
            {!noLabel && <label className="mb-5">{t("Chemical structure")}:</label>}
            <div
                ref={kekuleContainerRef}
                onClick={openEditor}
                className={classnames("chem-viewer", { readonly })}
                style={{ width: "100%", height: 150 }}
            >
                {/* this is kekule div container */}
            </div>
        </div>
    );
};
