import React, { useState } from "react";

import { createPortal } from "react-dom";

import { useMountTransition } from "hooks/useMountTransition";
import { Chatbot } from "components/chatbot/chatbot";
import { GT } from "components/index";

export const FilterChatBot = ({ isChatbotOpen, setIsChatbotOpen, onReload }) => {
    const [isMinimized, setIsMinimized] = useState<boolean>(false);
    const [areFiltersApplied, setAreFiltersApplied] = useState<boolean>(false);

    const chatBotMountTransition = useMountTransition(isChatbotOpen, 500);

    const onClick = () => {
        if (isMinimized) setIsMinimized(false);
        else {
            setIsChatbotOpen((prev) => !prev);
            if (areFiltersApplied && onReload) {
                onReload("?pageSize=15&page=1");
                setAreFiltersApplied(false);
            }
        }
    };

    return (
        <>
            <GT.Button onClick={onClick} icon="filter" withoutPerm />
            {(isChatbotOpen || chatBotMountTransition) &&
                createPortal(
                    <Chatbot
                        closing={!isChatbotOpen}
                        opened={chatBotMountTransition}
                        closeChatbot={() => setIsChatbotOpen(false)}
                        onReload={onReload}
                        isMinimized={isMinimized}
                        areFiltersApplied={areFiltersApplied}
                        setAreFiltersApplied={setAreFiltersApplied}
                        setIsMinimized={setIsMinimized}
                    />,
                    document.body
                )}
        </>
    );
};
