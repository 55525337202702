import React, { useState } from "react";

import { TooltipHOC } from "components/gt-tooltip/gt-tooltip-hoc";
import { useMountTransition } from "hooks/useMountTransition";
import { ModalPlayer } from "./modals/modal-player";
import { IListFile } from "./custom-files-list";
import Icon from "components/icon";

interface IMediaPlayer {
    file: IListFile;
    getFileIcon: (fileName: string) => string;
}

export const MediaPlayer = (props: IMediaPlayer) => {
    const { file, getFileIcon } = props;

    const [isPlayerOpen, setIsPlayerOpen] = useState<boolean>(false);

    const hasTransitionedIn = useMountTransition(isPlayerOpen);

    return (
        <TooltipHOC tooltipContent={"Play"} className="media-img bg-transparent" onClick={() => setIsPlayerOpen(true)}>
            <div className="player">
                <Icon name={["fas", "circle-play"]} />
            </div>
            <img src={getFileIcon(file.fileName || "")} className="icon-file" alt="" />
            {(isPlayerOpen || hasTransitionedIn) && (
                <ModalPlayer file={file} modalOpen={isPlayerOpen} closeModal={() => setIsPlayerOpen(false)} />
            )}
        </TooltipHOC>
    );
};
