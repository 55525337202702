import React, { CSSProperties, ReactNode, useRef, useState, MouseEventHandler } from "react";

import { GtTooltip, TooltipContentType } from "./gt-tooltip";
import Popper from "popper.js";

interface GtTooltipHOCProps {
    children: ReactNode;
    tooltipContent: TooltipContentType;
    placement?: Popper.Placement;
    style?: CSSProperties;
    className?: string;
    disableTranslation?: boolean;
    onClick?: MouseEventHandler<HTMLDivElement>;
}

export const TooltipHOC = ({
    children,
    tooltipContent,
    placement,
    style = {},
    onClick = undefined,
    className = "",
    disableTranslation = false,
}: GtTooltipHOCProps) => {
    if (tooltipContent === undefined) return <>{children}</>;

    const ref = useRef<HTMLDivElement>(null);

    return (
        <>
            <div className={className} onClick={onClick} ref={ref}>
                {children}
            </div>
            <GtTooltip targetRef={ref} tooltipContent={tooltipContent} placement={placement} disableTranslation={disableTranslation} />
        </>
    );
};
