import React, { CSSProperties, useRef } from "react";

import { GtTooltip } from "../gt-tooltip/gt-tooltip";
import classNames from "classnames";
import Icon from "../icon";

import "./gt-google-map-marker.style.scss";

interface GoogleMapReactMarkerProps {
    $hover?: boolean;
    lat?: number;
    lng?: number;
}

interface GtMarkerProps extends GoogleMapReactMarkerProps {
    tooltip?: string;
    iconType?: "pin" | "dot";
    pinIndex?: number;
    styles?: CSSProperties;
    hover?: boolean;
    className?: string;
}

export const GtMarker = (props: GtMarkerProps) => {
    const { tooltip, iconType = "dot", pinIndex, styles, hover, className = "" } = props;

    const ref = useRef<HTMLDivElement>(null);

    return (
        <>
            <GtTooltip targetRef={ref} placement="top" tooltipContent={tooltip || ""} className="gt-marker-tooltip" />
            <div id="gt-marker-id" className={classNames({ [className]: !!className })} ref={ref}>
                <div className={classNames("gt-marker", { hover: hover || props.$hover })} style={styles}>
                    <Icon name={["fas", `location-${iconType}`]} />
                    {pinIndex != undefined && iconType == "pin" && <div className="pin-index">{pinIndex + 1}</div>}
                </div>
            </div>
        </>
    );
};
