import React from "react";
import { SplitButton as SplBtn } from 'primereact/splitbutton';
import './split-button-style.scss'
import { useTranslation } from "react-i18next";
import hasPermission from "utils/hasPermissions";
import classnames from "classnames/dedupe";


export interface ISplitButtonItem {
  label: string;
  command: (e) => void;
  permcode: string;
  icon?: string;
}

interface ISplitButtonProps {
  label: string;
  onClick: (e?: any) => void;
  options: ISplitButtonItem[];
  children?: any;
  disabled?: boolean;
  permcode: string;
}

const SplitButton = (props: ISplitButtonProps) => {
    const { t } = useTranslation();
    const { label, onClick, options, disabled, permcode } = props;

    const handleClick = (e) => {
        onClick();
    };

    const filteredOptions = (() => (
        options.filter(el => hasPermission(el.permcode))
    ))()

    return (
        <SplBtn
            label={t(label)}
            icon="pi pi-plus"
            onClick={handleClick}
            model={filteredOptions}
            className={classnames("gt-split-button", {"main-disabled": !hasPermission(permcode), "dropdown-button-disabled": filteredOptions.length == 0})}
            disabled={disabled}
        />
    )
}

export default SplitButton;
