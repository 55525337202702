import React, { MouseEvent, useMemo, useRef, useState } from "react";
import { ContextMenu } from "primereact/contextmenu";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import axios from "axios";

import CustomButton from "../custom-button/custom-button";
import { MenuItem } from "primereact/menuitem";
import Icon from "../icon";

import { userManagementUrls } from "pages/BasicSettings/UsersManagement/urls/urls";
import { PdfPreviewModal } from "components/PdfPreview/PdfPreviewModal";
import { sweetConfirm } from "components/sweet-alert/sweetConfirm";
import { VerifyPreviewModal } from "./modals/verify-preview-modal";
import { ModalPlayer } from "./modals/modal-player";
import addToast from "utils/addToast";
import { TooltipHOC } from "..";

import "./style.scss";
import { MediaPlayer } from "./media-player";

export interface IListFile {
    id: number;
    order: string;
    fileName: string;
    externalId: null;
    file: string;
    type: string;
    company: number;
}

type ModalTypes = "verify" | "pdfPreview";

interface IOpenModalConfig {
    openedModal: ModalTypes;
    additionalData?: any;
}

const CustomFilesList = ({ files, handleDeleteFile, settings, permCode, reqModal, digitalSignature = true }) => {
    const cm = useRef<ContextMenu>(null);
    const { t } = useTranslation();

    const [contextSelectedFile, setContextSelectedFile] = useState<IListFile | undefined>(undefined);
    const [openModalConfig, setOpenModalConfig] = useState<IOpenModalConfig | undefined>(undefined);

    const getFileIcon = (filename) => {
        let split_path = filename.split(".");
        let ext = split_path[split_path.length - 1].toLowerCase();
        if (ext == "jpeg") ext = "jpg";
        return settings.img_file[ext] || settings.img_file["empty"];
    };

    const handleDownloadFile = (file: IListFile) => {
        axios.get(file.file, { responseType: "blob" }).then((response) => {
            const url = window.URL.createObjectURL(response.data);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            a.download = file.fileName;

            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
        });
    };

    const getFile = (file: string | undefined): string | undefined => {
        if (!file) return undefined;

        let strToExclude = file.getBefore("media/");

        if (!strToExclude) return undefined;

        return file.getAfter(strToExclude);
    };

    const handleVerify = async () => {
        try {
            if (!(await sweetConfirm("_file>>Verify", { text: `Do you want to verify this file?` }))) return;

            const response = await axios.post(userManagementUrls.verifyFile, { file: getFile(contextSelectedFile?.file) });

            if (response.data.length == 0) addToast({ title: `File must be signed to be verified.` });
            else {
                addToast({ title: `File has been verified.` });
                setOpenModalConfig({ openedModal: "verify", additionalData: response.data });
            }
        } catch (e) {}
    };

    const handleSign = async () => {
        try {
            if (!(await sweetConfirm("_file>>Sign", { text: `Do you want to sign this file?` }))) return;

            await axios.post(userManagementUrls.signFile, { file: getFile(contextSelectedFile?.file) });

            addToast({ title: `File has been signed.` });
        } catch (e) {}
    };

    const contextModel: MenuItem[] = useMemo(() => {
        const isFileNotPdf: boolean = !contextSelectedFile?.fileName.endsWith(".pdf");

        let _contextModel: MenuItem[] = [
            {
                label: t("Pdf preview"),
                icon: "pi pi-fw pi-file-pdf",
                disabled: isFileNotPdf,
                command: () =>
                    setOpenModalConfig({
                        openedModal: "pdfPreview",
                        additionalData: { fileName: contextSelectedFile?.fileName, file: contextSelectedFile?.file },
                    }),
            },
        ];

        digitalSignature &&
            _contextModel.push(
                {
                    label: t("_file>>Verify"),
                    icon: "pi pi-fw pi-check",
                    disabled: isFileNotPdf,
                    command: handleVerify,
                },
                {
                    label: t("_file>>Sign"),
                    icon: "pi pi-fw pi-pencil",
                    disabled: isFileNotPdf,
                    command: handleSign,
                }
            );

        return _contextModel;
    }, [contextSelectedFile]);

    const handleContextMenu = (e: MouseEvent<HTMLDivElement>, file: IListFile) => {
        if (contextModel.length == 0) return;

        cm.current?.show(e);
        setContextSelectedFile(file);
    };
    // mkv, avi (AV1, H264, H265, mpeg-1, mpeg -2, mpeg-4 i dźwięku aac, mpeg-1, mp3, vorbis, FLAC, Apple loosses (ALAC).
    const VIDEO_SUPPORTED_FORMATS = [
        ".mp4",
        ".mkv",
        ".vob",
        ".flv",
        ".m4p",
        ".m4v",
        ".mpg",
        ".mpeg",
        ".mpv",
        ".mpe",
        ".mp2",
        ".m2v",
        ".m4v",
        ".avi",
        ".3gp",
        "3g2",
    ];

    const AUDIO_SUPPORTED_FORMATS = [".aac", ".ogg", ".oga", ".mogg", ".flac", ".alac", ".m4b", ".m4a", ".mp3"];

    const PLAYER_FORMATS = [...VIDEO_SUPPORTED_FORMATS, ...AUDIO_SUPPORTED_FORMATS];

    const splitAt = (index, s) => [s.slice(0, index), s.slice(index)];

    return (
        <>
            <ContextMenu onHide={() => setContextSelectedFile(undefined)} model={contextModel} ref={cm}></ContextMenu>
            {files.map((file: IListFile, index: number) => (
                <div key={file.id} className="media media-retiring file-list-element" onContextMenu={(e) => handleContextMenu(e, file)}>
                    <div className="media-link">
                        {PLAYER_FORMATS.includes(splitAt(file.fileName.lastIndexOf("."), file.fileName)[1]) ? (
                            <MediaPlayer file={file} getFileIcon={getFileIcon} />
                        ) : (
                            <span className="media-img bg-transparent">
                                <img src={getFileIcon(file.fileName || "")} className="icon-file" alt="" />
                            </span>
                        )}
                        <span className="media-body">
                            <TooltipHOC tooltipContent={file.fileName}>
                                <span className="media-title">{file.fileName} </span>
                            </TooltipHOC>
                            <small className="media-subtitle pointer" onClick={() => handleDownloadFile(file)}>
                                {t("Download")}
                            </small>
                        </span>
                    </div>
                    {handleDeleteFile && (
                        <CustomButton
                            onClick={(e, reqParams) => handleDeleteFile(file.id, index, e, reqParams)}
                            className="media-icon pointer mt-0 border-0 btn-remove-attachment"
                            color="transparent"
                            permCode={permCode}
                            reqModal={reqModal}
                        >
                            <Icon name="x" />
                        </CustomButton>
                    )}
                </div>
            ))}
            {openModalConfig?.openedModal == "pdfPreview" && (
                <PdfPreviewModal closeModal={() => setOpenModalConfig(undefined)} file={openModalConfig.additionalData} />
            )}
            {openModalConfig?.openedModal == "verify" && (
                <VerifyPreviewModal closeModal={() => setOpenModalConfig(undefined)} verifications={openModalConfig.additionalData} />
            )}
        </>
    );
};

export default connect(({ settings }) => ({ settings }))(CustomFilesList);
