import React, { useRef, useState } from "react";

import { AudioRecorder } from "components/chatbot/components/audio-recorder";
import { InputText } from "primereact/inputtext";
import { useTranslation } from "react-i18next";
import classnames from "classnames/dedupe";

import { I } from "components/prime-data-table";
import { GT, Icon } from "..";

import "./chatbot.styles.scss";

interface IChatBot {
    closing: boolean;
    opened: boolean;
    className?: string;
    isMinimized: boolean;
    areFiltersApplied: boolean;
    setAreFiltersApplied: React.Dispatch<React.SetStateAction<boolean>>;
    setIsMinimized: React.Dispatch<React.SetStateAction<boolean>>;
    closeChatbot: () => void;
    onReload: I.OnReloadType | undefined;
}

interface IMessage {
    id: number;
    content: string;
    author: "chat" | "user";
    status: "pending" | "editable" | "default";
}

export const Chatbot = ({
    className,
    closing,
    opened,
    isMinimized,
    areFiltersApplied,
    setAreFiltersApplied,
    setIsMinimized,
    closeChatbot,
    onReload,
}: IChatBot) => {
    const inputRef = useRef<InputText & HTMLInputElement>(null);
    const ulRef = useRef<HTMLUListElement>(null);

    const [input, setInput] = useState<string>("");

    const [isPending, setIsPending] = useState<boolean>(false);

    const { t } = useTranslation();

    const [messages, setMessages] = useState<IMessage[]>([
        {
            id: 0,
            content: t("Hi, I am chatbot filtering assistance! How can I help you?"),
            author: "chat",
            status: "default",
        },
    ]);

    const clearFilters = () => {
        onReload && onReload("?pageSize=15&page=1");
        setAreFiltersApplied(false);
    };

    const onChange = (e) => {
        const { value } = e.target;
        setInput(value);
    };

    const changePreviousMessage = (pendingResponse, content) => {
        setMessages((prev) => {
            if (!pendingResponse) {
                const lastMessage = prev[prev.length - 1];

                return [
                    ...prev.filter((message) => message.id != prev.length - 1),
                    { ...lastMessage, status: "default", content: content },
                ];
            } else {
                return [...prev, { author: "chat", id: prev.length, status: "default", content: content }];
            }
        });
    };

    const onInputSend = async (e) => {
        const value = inputRef.current?.value;

        if (e.key == "Enter" && inputRef.current) {
            setIsPending(true);

            setMessages((prev) => [
                ...prev,
                {
                    content: value as string,
                    id: prev.length,
                    author: "user",
                    status: "default",
                },
            ]);
            setTimeout(() => {
                ulRef.current?.scrollTo({ top: ulRef.current.scrollHeight });
            }, 0);

            let pendingResponse: boolean = true;

            setInput("");

            await setTimeout(async () => {
                if (pendingResponse)
                    setMessages((prev) => [
                        ...prev,
                        {
                            id: prev.length,
                            content: "",
                            author: "chat",
                            status: "pending",
                        },
                    ]);

                pendingResponse = false;
            }, 500);

            if (onReload) {
                const response = await onReload(`?advancedFilter=${value as string}`);

                // if error
                if (response?.response?.status) {
                    changePreviousMessage(pendingResponse, t("Cannot apply filters to table."));
                    setIsPending(false);
                    return;
                }
            }

            if (pendingResponse) pendingResponse = false;

            changePreviousMessage(pendingResponse, t("Applied filters to table."));
            setAreFiltersApplied(true);
            setIsPending(false);
        }
    };

    return (
        <div
            className={classnames("chatbot-container", className, {
                minimized: isMinimized,
                closing,
                opened,
            })}
        >
            <div className="chatbot-content" onClick={isMinimized ? () => setIsMinimized(false) : undefined}>
                <div className={isMinimized || closing ? "d-none" : undefined}>
                    <div className="chatbot-header">
                        {t("Chatbot filtering assistance")}

                        {areFiltersApplied && (
                            <div role="button" className="clear-filters-btn" onClick={clearFilters}>
                                {t("Clear filters")}
                            </div>
                        )}

                        <div className="chatbot-buttons-container">
                            <div role="button" className="chatbot-mm-btn" onClick={() => setIsMinimized(true)}>
                                <Icon name="minus" />
                            </div>
                            <div role="button" className="chatbot-close-btn" onClick={closeChatbot}>
                                <Icon name="X" />
                            </div>
                        </div>
                    </div>
                    <div className="chatbot-output-content">
                        <ul ref={ulRef}>
                            {messages.map((message, index) => {
                                return (
                                    <li key={index} className={classnames("chatbot-output-item", message.author)}>
                                        {message.status === "pending" ? <Icon name={["fas", "ellipsis"]} bounce /> : message.content}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    <div className="chatbot-input-content">
                        <InputText
                            className="modal-input"
                            placeholder={t("Start typing...")}
                            ref={inputRef}
                            onKeyPress={onInputSend}
                            onChange={onChange}
                            value={input}
                            disabled={isPending}
                            spellCheck="false"
                        />
                        <AudioRecorder setInput={setInput} />
                    </div>
                </div>
                {isMinimized && <Icon name={["fas", "comment"]}></Icon>}
            </div>
        </div>
    );
};
