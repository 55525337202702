import React, { Ref, RefObject } from "react";

import { useTranslation } from "react-i18next";
import { XCircle } from "react-feather";

import "./styles.scss";

interface EmptyMessageProps {
    height?: number;
    tableRef?: RefObject<HTMLDivElement>;
}

export const EmptyMessage = ({ height, tableRef }: EmptyMessageProps) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="prime-data-table-message" style={{ height: `${(height || 10) - 10}px`, width: tableRef?.current?.clientWidth }}>
                <XCircle />
                <p>{t("No records found")}</p>
            </div>
        </>
    );
};
