import React, { useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import classnames from "classnames/dedupe";
import Axios from "axios";

import { detectSpeaking } from "../functions/detect-speaking";
import { handleRequest } from "utils/handleRequest";
import addToast from "utils/addToast";
import { GT } from "../..";

interface IAudioRecorder {
    setInput: React.Dispatch<React.SetStateAction<string>>;
}

export const AudioRecorder = ({ setInput }: IAudioRecorder) => {
    const [isRecording, setIsRecording] = useState<boolean>(false);
    const [isLoadingRecorder, setIsLoadingRecorder] = useState<boolean>(false);
    const [averageVolume, setAverageVolume] = useState<number>(0);
    const [isTranscribing, setIsTranscribing] = useState<boolean>(false);

    const { t } = useTranslation();

    const mediaStream = useRef<MediaStream | null>(null);

    const mediaRecorder = useRef<MediaRecorder | null>(null);
    const chunks = useRef<any[]>([]);

    // loop id, used for its later cancelation
    const animationFrame = useRef<number>();

    const startRecording = async () => {
        try {
            setIsLoadingRecorder(true);

            const stream = await navigator.mediaDevices.getUserMedia({ audio: { channelCount: 1 } });
            mediaStream.current = stream;

            detectSpeaking(stream, animationFrame, onSpeaking);

            mediaRecorder.current = new MediaRecorder(stream);
            mediaRecorder.current.ondataavailable = (e) => {
                if (e.data.size > 0) {
                    chunks.current.push(e.data);
                }
            };

            mediaRecorder.current.onstop = () => {
                const recordedBlob = new Blob(chunks.current, { type: "audio/mp3" });

                transcribeRecordedBlob(recordedBlob);
                chunks.current = [];
            };

            setIsLoadingRecorder(false);
            mediaRecorder.current.start();
            setIsRecording(true);
        } catch (error) {
            console.error("Error accessing microphone:", error);
            setIsLoadingRecorder(false);
            setIsRecording(false);
            addToast({ title: "Access denied", content: "Allow access to the microphone", color: "danger" });
        }
    };

    // cancel all subscriptions
    const stopRecording = () => {
        if (animationFrame.current) {
            cancelAnimationFrame(animationFrame.current);
            setAverageVolume(0);
        }
        if (mediaRecorder.current && mediaRecorder.current.state === "recording") {
            mediaRecorder.current.stop();
            setIsRecording(false);
        }
        if (mediaStream.current) {
            mediaStream.current.getTracks().forEach((track) => {
                track.stop();
            });
        }
    };

    const transcribeRecordedBlob = async (recordedBlob) => {
        if (!recordedBlob) return;
        setIsTranscribing(true);

        const formData = new FormData();
        formData.append("recording", recordedBlob);

        await handleRequest({
            request: () => Axios.post("transcribe", formData),
            onSuccess: (response) => {
                setInput(response.data?.transcription);
            },
        });

        setIsTranscribing(false);
    };

    // onSpeak callback, gets the average microphone value and sets it to btn outline
    const onSpeaking = (decibels) => {
        let sum: number = 0;
        decibels.forEach((value) => (sum += value));

        const avg = Math.ceil(sum / decibels.length);
        const MAX_OUTLINE = 15;

        setAverageVolume(avg > MAX_OUTLINE ? MAX_OUTLINE : avg);
    };

    const getButtonText = () => {
        if (isLoadingRecorder) return "Loading...";
        else if (isRecording) return t("Recording");
        else if (isTranscribing) return t("Transcribing");
    };

    return (
        <GT.Button
            icon={{ name: ["fas", "microphone"], beatFade: isRecording }}
            withoutPerm
            className={classnames("record-btn", { recording: isRecording, loading: isLoadingRecorder || isTranscribing })}
            onClick={!isRecording ? startRecording : stopRecording}
            style={{ outline: `${averageVolume}px solid` }}
            text={getButtonText()}
        >
            <div className="o-hidden">{isRecording && <span className="ripple"></span>}</div>
        </GT.Button>
    );
};
