import React, { useState } from "react";

import { Modal, ModalBody } from "reactstrap";
import { createPortal } from "react-dom";

import { KekuleInput } from "components/kekule-input/kekule-input";
import { TooltipHOC } from "components/gt-tooltip/gt-tooltip-hoc";
import { useMountTransition } from "hooks/useMountTransition";
import { IKekuleCellProps } from "../interfaces";
import { GT } from "components/index";
import { getNestedObjValue } from "../helpers";

export const KekuleCell = ({ rowData, className = "", style = {}, value = undefined }: IKekuleCellProps) => {
    const { row, fieldName } = rowData;

    const nestedValue = getNestedObjValue(rowData.row, rowData.fieldName.split("."));

    if (!(value || nestedValue)) return <></>;

    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const hasTransitionedIn = useMountTransition(modalOpen);

    return (
        <>
            <TooltipHOC tooltipContent="Preview" className="cell-tooltip d-flex justify-content-center" onClick={() => setModalOpen(true)}>
                <GT.Icon name={["fas", "magnifying-glass"]} />
            </TooltipHOC>
            {(modalOpen || hasTransitionedIn) &&
                createPortal(
                    <KekuleCellModal modalOpen={modalOpen} closeModal={() => setModalOpen(false)} value={value || nestedValue} />,
                    document.body
                )}
        </>
    );
};

interface IKekuleCellModalProps {
    modalOpen: boolean;
    value: any;
    closeModal: () => void;
}

const KekuleCellModal = ({ modalOpen, value, closeModal }: IKekuleCellModalProps) => {
    return (
        <Modal isOpen={modalOpen} fade toggle={closeModal}>
            <GT.ModalHeader closeModal={closeModal} name={"Chemical structure"} />
            <ModalBody>
                <KekuleInput readonly value={value} noLabel />
            </ModalBody>
            <GT.ModalFooter closeModal={closeModal} cancelName="Close" />
        </Modal>
    );
};
